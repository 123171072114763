<template>
  <div class="auth-wrapper auth-v1 px-2" >
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
      <div align="center">
        <img src="https://nerd.buzzprivilegewifi.com/upload/nerd/nerdLogo.gif" 
        style="object-fit:contain;
                 width:220px;"/>
      </div>

        <validation-observer
        style="margin-top: -20em;"
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
         
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
      
              @click="validationForm()"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>


      
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import api from "@/api";
export default {
  components: {
    ToastificationContent,
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
         
          const params = {
            email: this.userEmail,
            password: this.password,
          };

          api
            .post("/login", params)
            .then((response) => {
              useJwt.setToken(response.data.accessToken);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem("userData", JSON.stringify(response.data));
              this.$ability.update(response.data.ability);
              // window.location.reload();
              this.$router
                .replace(getHomeRouteForLoggedInUser("admin"))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Welcome ${response.data.name}`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `You have successfully logged in as Admin. Now you can start to explore!`,
                    },
                  });
                });
            })
            .catch((err) => {
              this.$swal({
                title: "เช็กข้อมูลของท่านอีกครั้ง!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';


.auth-wrapper.auth-v1 {
  background-image: url('https://nerd.buzzprivilegewifi.com/upload/nerd/Nerd1.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
